import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Industry = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            
            <h1>Industry 4.0</h1>
            <p>
                Industry 4.0, smart factory, factory of the future, call it as you like, it refers to 
                the 4th industrial revolution, which digitally interconnects all assets, and which soon 
                be followed by the 5.0, the symbiotic relation between humans and digital systems, but 
                let's take this all step by step.
                <br /> <br />
                First of all, let's be very pragmatic, the world of "big data" did not reach factories, 
                yet, there is in reality few data to connect.
                <br />No data, no 4.0, and a digital transformation project that will probably quickly 
                turn to frustration.
                <br /> <br />
                Secondly, data is not an end in itself, it must be cleansed and, considering the maturity 
                of current digital technologies, humans are in control.
                <br />Artificial intelligence made progress, we do not consider maturity of AI Automation 
                before 2030.
                <br /> <br />
                Nowlook helps you in a very pragmatic manner,
                <br />- Taking advantage of proven solutions to collect data,
                <br />- Providing contextual information to data for better decision making. We touch 
                here our core technology, the "how", and it may surprise, what we propose is unique and so simple, 
                so different from major players in the 4.0 scene selling dreams, that you could miss good opportunities 
                of social and economic developments.
                <br /> <br />
                <h3>Digital Twin</h3>
                A digital twin is a representation of a physical assset that provides data with contextual information.
                <br />
                It's objective is the decision-making, in real-time, and the context that we install around the data is 
                fundamental in this process.
                <br /><br />
                Several digital twins may go together, once per machine, another on top for groups of machines, including 
                material and information flows, or concecutive twins following an asset lifetime.
                <br />Depending access right, user will access to the data he needs for taking decisions at his level.
                <br />
                <br /><strong>At operational level</strong>, for process equipment
                <br />- scrap rate, cycle time, proactiveness on change-overs
                <br />- Condition based maintenance, which alerts only when necessary, before an issue
                <br />
                <br /><strong>At manager level</strong>, for a manufacturing plant
                <br />- process availibility, anticipation of production capacity, 
                <br />- Continuous improvement, quality workshops, Value stream VSM/VSD etc.,
                <br />- Transversal collaboration
                <br />
                <br /><strong>At global managers level</strong>, for a group of plants
                <br />- Best practice share
                <br />- Remote assit, including data analytics
                <br />- Globale optimisation
                <br /><br /><br />
                <h2>NowLook solutions, a mix of 3D and 360°</h2>
                Our solutions are based on underlying principles:
                <br />
                <br />a) Full autonomy in managing contents like images, video or text
                <br />b) Cyber-sécurity, protection of data and accesses
                <br />c) Pleasant and efficient user interface, with the idea to find any information in three clicks, 
                even in a complex world of "big data"
                <br />d) An alert system for real-time data stream
                <br />
                <h3>Building block 1: digital representation</h3>
                It may be a 3D representation to materialize spaces, or, less costly, more simple maps, suggestive 
                enough to provide context to data
                <br />- highlights assets under alert
                <br />- geolocalize mobile assets, indoor, and outdoor bewteen plants or between a plant and 
                customers or suppliers. Isolated workers may be followed as well for safety reason.
                <br />
                <h3>Building block 2: 360°</h3>
                360° is a center piece of digital twin, it's both data and context, and it has a tremendous potential 
                of developement if we consider the progress of <strong>Artificial Intelligence (AI)</strong> in image 
                processing. We must consider that networks will support video analysis in real time, data storage 
                is not costly anymore with cloud and data lake solutions.
                <br /><br />
                Future starts now, conditions for success exist today, it's a matter of making the first move and 
                gradually scale up, we will support you:
                <br /><br />
                <br />Implement <strong>360° walk-through</strong> , with real time update. Users updates 
                their 360° digital twin in our platform by themselve, it's intuitive, quick and easy.
                <br />By the way, we prepare future evolutions, automated updates with on site cameras, but let's 
                make profits today with low-hanging fruits.
                <br /><br />
                <br />Connect to <strong>valuable and reliable data stream</strong>
                <br />Data may be pulled from servers, but consider that digital twin being a working tool is a 
                great way to create data with the advantage that this kind of data includes context, geolocalization 
                in the map and localy in the 360° scene. A machine maybe tagged with a smartphone photo to alert in case 
                of a none conformance is observed, or after a repair was done for aknowledgement.
                <br />When walking, workers are augmented with all flows of information provided by the digital twin, 
                and in return, as it's a live tool, workers feed the digital twin with valuable information, it's a 
                virtuous circle.
                <br /><br />
                You take the idea now, we propose a tool box, always up to date because it's a centralized trustable 
                source of needed information, and an interactive tool that allow workers to feel controling the system, 
                and not the other way around.
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
