import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EspaceCartoon_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Cartoons in 3D</h1>
            <p>
                A cartoon is a type of illustration which simplifies reality, a style with multiple facets that reflect 
                light, providing pleasant models
                <br /><br />
                Attractive light and shadow effects, transparencies, you feel in a virtual twin of reality.
                <br /><br />
                We propose this cartoon mode to simplify communication and focus on points of interrest, with hyper zoom 
                or bird eye view, in visible spaces or hidden places.
                <br /><br />
                Ideally, it must be designed to trigger surprise and willingness to continue discovering. There is nothing 
                simplistic here, quite the contrary, it must be well though to cause the aesthetic emotion.
                <br /><br />
                Take the lead, use your finger or mouse buttons, double clic to teleport and zoom.
                <br />While flying, you'll find a cormorant with wings spread, stylized fish around a lightship, 
                ladybug flying in a car, fishermen arguing on docks.
                <br />
            </p>
            <Viewer id='36c729380029423a9a401c4e59694493' />
            <p>
                Interest lies in basic human desire to master their environment, fly like birds, pass through matter, be free.
                <br /><br />
                From early age, we love small worlds, Nowlook works diligently to reproduce this deep and intense feeling.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
