import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Motorhome = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Motorhome</h1>
            
            <p>
                <br />This model was modeled from smartphone photos for the external surfaces and 
                our 360 GoPro Max for the internal walk-through. 
                <br />Volumes were added inside as well as animations such as night mode and TV screen. 
                <br /><br />You'll notice that the user experience is very differente from a standard 
                360° Matterport visit, you provide a lot of information quick and interactive.
                <br /> <br />
                Enjoy!
                <br />
            </p>
                <Viewer id='94045cf36dee4c4a85df4c78afba963a' />
                <br /> <br />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
