import Button from '@material-ui/core/Button'
import { HashLink } from 'react-router-hash-link'
import React, { memo } from 'react'
import logo from '../assets/logo-full-white.svg'
import logo3D from '../assets/3D.svg'
import logoAnimations from '../assets/animations.svg'
import logoLight from '../assets/light.svg'
import logoVR from '../assets/VR.svg'
import './index.scss'
import { Viewer } from '../viewer'
import LocalFlorist from '@material-ui/icons/LocalFlorist'

import EmojiTransportation from '@material-ui/icons/EmojiTransportation'
import { Link } from 'react-router-dom'


export const Description_en = memo(() => {
    return (
        <div className='beeseen-block beeseen-description'>
            <div className='beeseen-block__content'>
                <div className='beeseen-description__content'>
                    <div className='beeseen-description__text'>
                    <Button
                        variant='contained'
                        color='secondary'
                        to='/'
                        component={HashLink}
                        className='beeseen-header__link'
                    >
                        Français
                    </Button>
                        
                        <h1 className='beeseen-description__text-title'>
                            <br />Innovative Solutions in Virtual Reality
                        </h1>
                        <div className='beeseen-description__text-general'>
                            Communicate <strong>in 4D</strong> for great success,
                            <br />
                            Immersion in 3D virtual spaces, with emotion on top, the 4th Dimension,
                            <br />for intense User eXperience
                        </div>
                    </div>
                    <div className='beeseen-description__logo'>
                        <div dangerouslySetInnerHTML={{ __html: logo }} />
                        <h3>4D Communication</h3>
                    </div>
                </div>
                {/* ------------------------ fenetre ardoise ------------------ */}
                <div className='beeseen-block beeseen-applications'>
                    {/* ------------- THEME 1 ------------ */}
                    <div className='beeseen-applications__block'>
                            <ul>
                            {/* <li> */}
                                    <h2>
                                        <strong>4D Building Blocks</strong>
                                    </h2>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-360'
                                component={Link}
                            >
                                360°
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-cartoon'
                                component={Link}
                            >
                                Cartoon
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-mixte'
                                component={Link}
                            >
                                Mix 360° - cartoon
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-3Drealiste'
                                component={Link}
                            >
                                3D realistic
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-3Dultra'
                                component={Link}
                            >
                                3D photo realistic
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/espace-3Dsat'
                                component={Link}
                            >
                                3D satellite
                            </Button>
                            {/* </li> */}
                            </ul>
                    
                            {/* ------------- THEME 2 ------------ */}
                    
                            <ul>
                                    {/* <h2>
                                        <strong>Value Creation</strong>
                                    </h2> */}
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/va-animations'
                                component={Link}
                            >
                                Animations
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/va-interactivite'
                                component={Link}
                            >
                                Interactivity
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/va-graphisme'
                                component={Link}
                            >
                                Graphic Design
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/va-physique'
                                component={Link}
                            >
                                Physical world
                                </Button>
                            </ul>
                    </div>
                </div>

                {/* -------------------------- espace bleu entre les fenêtres ------------------ */}
                <div className='beeseen-block beeseen-description'>
                    <div className='beeseen-block__content'>
                        <div className='beeseen-description__content'>
                        </div>
                    </div>
                </div>

                {/* ------------------------ fenetre ardoise ------------------ */}
                <div className='beeseen-block beeseen-applications'>
                    {/* ------------- THEME 1 ------------ */}
                    <div className='beeseen-applications__block'>
                            <ul>
                                <h2>
                                    <strong>Applications</strong>
                                </h2>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/entreprises-collab'
                                component={Link}
                            >
                                Collaboration Spaces
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/entreprises-controle'
                                component={Link}
                            >
                                Control Room
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/entreprises-salon'
                                component={Link}
                            >
                                Virtual Exhibitions
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/entreprises-implant'
                                component={Link}
                            >
                                Layout Simulation
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/entreprises-40'
                                component={Link}
                            >
                                Industry 4.0
                            </Button>
                            </ul>

                            {/* ------------- THEME 2 ------------ */}
                    
                            <ul>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/immo-1'
                                component={Link}
                            >
                                Walk-Through
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/motorhome'
                                component={Link}
                            >
                                Motorhome
                                </Button>
                            </ul>
                    
                            {/* ------------- THEME 3 ------------ */}
                    
                            <ul>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/tous-archi'
                                component={Link}
                            >
                                Architecture
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/tous-litho'
                                component={Link}
                            >
                                Litho 3D
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications_en/tous-meditation'
                                component={Link}
                            >
                                Meditation
                            </Button>
                            </ul>
                    </div>
                </div>
                <div>
                    <p>
                        <br />
                    </p>
                    <Button
                        variant='contained'
                        color='secondary'
                        to='/#contact'
                        component={HashLink}
                        >
                        Contact us
                    </Button>
                    <p>
                        <br />
                    </p>
                </div>
                





            </div>
        </div>
    )
})
