import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EspaceMixte = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le mixte 360° et Cartoon</h1>
            <p>
                Utiliser la force du réel avec la puissance évocatrice du cartoon. 
                <br /><br />
                Laissez vous guider par l'animation et entrez dans les ateliers
                <br />
            </p>
                <Viewer id='983761a6123d4b7891045406d3bafabd' />
            <p>
                <br /><br /><br />
            </p>
            <iframe 
                    width="100%"
                    height="600"
                    // src="https://nowlook.fr/static/MuseumA/"/>
                    src="https://nowlook.fr/static/fof3/"/>

            <p>
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
