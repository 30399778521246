import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousMeditation = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>La réalité virtuelle au service de la méditation</h1>
            <p>
                La méditation se pratique souvent devant une bougie, nous proposons une forme 
                digitale de mise en condition, l'immersion à 360° dans un paysage apaisant, 
                avec une ambiance sonore qui complète l'immersion.
                <br /><br />
                Avant de fermer les yeux, le méditant se laisse bercer, le paysage bouge comme la 
                flamme d'une bougie, doucement, il peut revenir à son soufle et avancer dans sa pratique.
                <br /><br />
                Inspirez et expirez doucement, la relaxation arrive rapidement, essayez par vous-même.
                <br /><br />
            </p>
            <Viewer id='0755e61c590044e0804f30a35d95c77f' />
            
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
