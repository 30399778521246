import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TourismeDecouverte = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Decouverte d'un territoire</h1>
            <p>
                Voici un jeu en mode cartoon qui vous fera découvrir le port Rhu de Douarnenez
                <br /><br />
                De fil en aiguille, vous accédez aux <strong>endroits remarquables</strong>, 
                mais quoi de mieux qu'un défi amical pour impliquer son interlocuteur ?
                <br />
                Trois coccinelles se cachent dans les maquettes, à vous de les trouver !
            </p>
            <Viewer id='beefffc84b2f485a802670e98424a52d' />
            <p>
                Vous avez repéré le bateau phare et les 360° de l'artisanat local ? 
                <br />
                Vous vous êtes déplacé de maquette en maquette, en suivant les annotations ?
                <br /> <br />
                Très bien !
                <br /><br />
                Et les coccinelles ? Si vous êtes passés à coté, elles sont cachées  
                dans l'atelier de voilerie, dans la voiture qui est garée sur le quai porte ouverte, 
                <br />Et la troisième ? C'est le skipper de l'imoca de tête sur la carte de la Bretagne !
                <br /><br />
                Félicitations à ceux qui les ont découvertes !
                <br /> <br /> 
                Voilà, vous venez d'expérimenter l'un de nos concepts de mise en scène. 
                <br /> <br />
            </p>

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
