import Button from '@material-ui/core/Button'
import React, { memo, useLayoutEffect, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import logo from '../assets/logo-small.svg'
import './index.scss'

export const Header = memo(() => {
    const history = useHistory()
    useEffect(() => {
        return history.listen(() => window.scrollTo(0, 0))
    }, [])
    return (
        <nav className='beeseen-block beeseen-header'>
            <div className='beeseen-block__content beeseen-header__content'>
                <Link
                    to='/'
                    className='beeseen-header__link beeseen-header__logo-link'
                >
                    <div
                        className='beeseen-header__logo'
                        dangerouslySetInnerHTML={{ __html: logo }}
                    />
                    NowLook

                </Link>
                
                <div className='beeseen-header__links'>
                    
                    <Button
                        variant='text'
                        color='secondary'
                        to='/about-us'
                        component={HashLink}
                        className='beeseen-header__link'
                    >
                        .
                    </Button>

                    <Button
                        variant='contained'
                        color='secondary'
                        to='/#contact'
                        component={HashLink}
                        className='beeseen-header__link'
                    >
                        Contact
                    </Button>
                </div>
            </div>
        </nav>
    )
})
