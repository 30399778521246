import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { About } from './about'
import { Applications } from './applications'
import { Health } from './applications/health'
import { Espace360 } from './applications/espace-360'
import { Espace360_en } from './applications_en/espace-360'
import { EspaceCartoon } from './applications/espace-cartoon'
import { EspaceCartoon_en } from './applications_en/espace-cartoon'
import { EspaceMixte } from './applications/espace-mixte'
import { EspaceMixte_en } from './applications_en/espace-mixte'
import { Espace3Drealiste } from './applications/espace-3Drealiste'
import { Espace3Drealiste_en } from './applications_en/espace-3Drealiste'
import { Espace3Dultra } from './applications/espace-3Dultra'
import { Espace3Dultra_en } from './applications_en/espace-3Dultra'
import { Espace3Dsat } from './applications/espace-3Dsat'
import { Espace3Dsat_en } from './applications_en/espace-3Dsat'
import { VaAnimations } from './applications/va-animations'
import { VaAnimations_en } from './applications_en/va-animations'
import { VaGraphisme } from './applications/va-graphisme'
import { VaGraphisme_en } from './applications_en/va-graphisme'
import { VaInteractivite } from './applications/va-interactivite'
import { VaInteractivite_en } from './applications_en/va-interactivite'
import { VaPhysique } from './applications/va-physique'
import { VaPhysique_en } from './applications_en/va-physique'
import { TourismeDecouverte } from './applications/tourisme-decouverte'
import { Industry } from './applications/industry'
import { EntreprisesImplant } from './exemples/entreprises-implant'
import { EntreprisesImplant_en } from './applications_en/entreprises-implant'
import { Museum } from './exemples/entreprises-museum'
import { Museum2 } from './exemples/entreprises-museum2'
import { Entreprises40 } from './exemples/entreprises-40'
import { Entreprises40_en } from './applications_en/entreprises-40'
import { Outside } from './applications/outside'
import { SmallSpaces } from './applications/small-spaces'
import { Contact } from './contact'
import { Description } from './description'
import { Description_en } from './description_en'
import { Header } from './header'
import './style/global.scss'
import { Tools } from './tools'
import { Legal } from './legal'
import { Footer } from './footer'
import { Exemples } from './exemples'
import { EntreprisesCollab } from './exemples/entreprises-collab'
import { EntreprisesCollab_en } from './applications_en/entreprises-collab'
import { EntreprisesControle } from './exemples/entreprises-controle'
import { EntreprisesControle_en } from './applications_en/entreprises-controle'
import { EntreprisesSalon } from './exemples/entreprises-salon'
import { EntreprisesSalon_en } from './applications_en/entreprises-salon'
import { Immo1 } from './exemples/immo-1'
import { Immo1_en } from './applications_en/immo-1'
import { Campingcar } from './exemples/camping-car'
import { Motorhome } from './applications_en/motorhome'
import { TousArchi } from './exemples/tous-archi'
import { TousArchi_en } from './applications_en/tous-archi'
import { TousLitho } from './exemples/tous-litho'
import { TousLitho_en } from './applications_en/tous-litho'
import { TousMeditation } from './exemples/tous-meditation'
import { TousMeditation_en } from './applications_en/tous-meditation'

ReactDOM.render(
    <ThemeProvider
        theme={createMuiTheme({
            palette: {
                primary: {
                    main: '#272932',
                },
                secondary: {
                    main: '#1e87af',
                },
            },
        })}
    >
        <BrowserRouter>
            <Header />
            <Switch>
                <Route exact path='/'>
                    <Description />
                    <Applications />
                    <Exemples />
                    <Contact />
                </Route>
                <Route path='/description_en'>
                    <Description_en />
                </Route>
                <Route path='/applications/espace-360'>
                    <Espace360 />
                </Route>
                <Route path='/applications_en/espace-360'>
                    <Espace360_en />
                </Route>
                <Route path='/applications/espace-cartoon'>
                    <EspaceCartoon />
                </Route>
                <Route path='/applications_en/espace-cartoon'>
                    <EspaceCartoon_en />
                </Route>
                <Route path='/applications/espace-mixte'>
                    <EspaceMixte />
                </Route>
                <Route path='/applications_en/espace-mixte'>
                    <EspaceMixte_en />
                </Route>
                <Route path='/applications/espace-3Drealiste'>
                    <Espace3Drealiste />
                </Route>
                <Route path='/applications_en/espace-3Drealiste'>
                    <Espace3Drealiste_en />
                </Route>
                <Route path='/applications/espace-3Dultra'>
                    <Espace3Dultra />
                </Route>
                <Route path='/applications_en/espace-3Dultra'>
                    <Espace3Dultra_en />
                </Route>
                <Route path='/applications/espace-3Dsat'>
                    <Espace3Dsat />
                </Route>
                <Route path='/applications_en/espace-3Dsat'>
                    <Espace3Dsat_en />
                </Route>
                <Route path='/applications/va-graphisme'>
                    <VaGraphisme />
                </Route>
                <Route path='/applications_en/va-graphisme'>
                    <VaGraphisme_en />
                </Route>
                <Route path='/applications/va-animations'>
                    <VaAnimations />
                </Route>
                <Route path='/applications_en/va-animations'>
                    <VaAnimations_en />
                </Route>
                <Route path='/applications/va-interactivite'>
                    <VaInteractivite />
                </Route>
                <Route path='/applications_en/va-interactivite'>
                    <VaInteractivite_en />
                </Route>
                <Route path='/applications/va-physique'>
                    <VaPhysique />
                </Route>
                <Route path='/applications_en/va-physique'>
                    <VaPhysique_en />
                </Route>
                <Route path='/exemples/tourisme-decouverte'>
                    <TourismeDecouverte />
                </Route>
                <Route path='/exemples/entreprises-collab'>
                    <EntreprisesCollab />
                </Route>
                <Route path='/applications_en/entreprises-collab'>
                    <EntreprisesCollab_en />
                </Route>
                <Route path='/exemples/entreprises-controle'>
                    <EntreprisesControle />
                </Route>
                <Route path='/applications_en/entreprises-controle'>
                    <EntreprisesControle_en />
                </Route>
                <Route path='/exemples/entreprises-salon'>
                    <EntreprisesSalon />
                </Route>
                <Route path='/applications_en/entreprises-salon'>
                    <EntreprisesSalon_en />
                </Route>
                <Route path='/exemples/immo-1'>
                    <Immo1 />
                </Route>
                <Route path='/applications_en/immo-1'>
                    <Immo1_en />
                </Route>
                <Route path='/exemples/camping-car'>
                    <Campingcar />
                </Route>
                <Route path='/applications_en/motorhome'>
                    <Motorhome />
                </Route>
                <Route path='/exemples/entreprises-40'>
                    <Entreprises40 />
                </Route>
                <Route path='/exemples/tous-archi'>
                    <TousArchi />
                </Route>
                <Route path='/applications_en/tous-archi'>
                    <TousArchi_en />
                </Route>
                <Route path='/exemples/tous-litho'>
                    <TousLitho />
                </Route>
                <Route path='/applications_en/tous-litho'>
                    <TousLitho_en />
                </Route>
                <Route path='/exemples/tous-meditation'>
                    <TousMeditation />
                </Route>
                <Route path='/applications_en/tous-meditation'>
                    <TousMeditation_en />
                </Route>
                <Route path='/applications/industry'>
                    <Industry />
                </Route>
                <Route path='/exemples/entreprises-implant'>
                    <EntreprisesImplant />
                </Route>
                <Route path='/applications_en/entreprises-implant'>
                    <EntreprisesImplant_en />
                </Route>
                <Route path='/exemples/entreprises-museum'>
                    <Museum />
                </Route>
                <Route path='/exemples/entreprises-museum2'>
                    <Museum2 />
                </Route>
                <Route path='/exemples/entreprises-40'>
                    <Entreprises40 />
                </Route>
                <Route path='/applications_en/entreprises-40'>
                    <Entreprises40_en />
                </Route>
                <Route path='/applications/outside'>
                    <Outside />
                </Route>
                <Route path='/applications/health'>
                    <Health />
                </Route>
                <Route path='/applications/small-spaces'>
                    <SmallSpaces />
                </Route>
                <Route path='/about-us'>
                    <About />
                    <Tools />
                </Route>
                <Route path='/legal'>
                    <Legal />
                </Route>
                <Redirect to='/' />
            </Switch>
            <Footer />
        </BrowserRouter>
    </ThemeProvider>,
    document.getElementById('react-root'),
)
