import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace3Dultra = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le 3D photo-réaliste</h1>
            <p>
                le modèle ci-dessous, réalisé avec un drone, offre une très belle 
                expérience de réalité virtuelle. 
                <br />Le château de Gavaudin a été scanné avec un drone 
                et reconsistitué en 3D.
                <br /> <br />
                Vous y êtes, profitez-en, double cliquez, zoomez, déplacez-vous au pied des remparts, 
                monter dans la tour, découvrez le site et imaginez comment valoriser votre patrimoine, 
                vous aussi.
                <br />
            </p>
            <Viewer id='5fc87855b67b4d3ebba9b0166ffca0f3' autoplay={false} />
            <p>
                Il vous faut néanmoins considérer que ce type de modèle 
                peut être lourd à charger et, faute de bonne connexion internet, le temps de 
                chargement pourra gêner la fluidité de navigation.
                <br /> <br />
                Le prix d'une telle prestation est aussi à prendre en compte, il faut du matériel 
                dédié et une prestation de prise de vue en plus de la constitution du modèle 3D.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
