import { ChangeEvent, useCallback, useState, ReactNode } from 'react'

const useTextFieldOptions = (initialValue = '') => {
    const [value, setValue] = useState(initialValue)
    const [touched, setTouched] = useState(false)
    const [dirty, setDirty] = useState(false)
    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setValue(event.target.value)
            setTouched(true)
        },
        [],
    )
    const onBlur = useCallback(() => {
        setDirty(true)
    }, [])
    const reset = useCallback(() => {
        setValue(initialValue)
        setTouched(false)
        setDirty(false)
    }, [initialValue])
    return {
        value,
        onChange,
        setValue,
        onBlur,
        touched,
        setTouched,
        untouched: !touched,
        dirty,
        setDirty,
        pristine: !dirty,
        reset,
    }
}

export const useTextField = ({
    initialValue = '',
    isValid,
    invalidMessage = 'Invalid',
    cleanup = (newValue) => newValue.trim(),
    disabled,
}: {
    initialValue?: string
    isValid?: (value: string) => boolean
    invalidMessage?: ReactNode | ((value: string) => ReactNode)
    cleanup?: (value: string) => string
    disabled?: boolean
} = {}) => {
    const {
        value,
        onChange,
        setValue,
        onBlur,
        touched,
        setTouched,
        untouched,
        dirty,
        setDirty,
        pristine,
        reset,
    } = useTextFieldOptions(initialValue)
    const cleanedValue = cleanup(value)
    const valid = !isValid || isValid(cleanedValue)
    return {
        properties: {
            defaultValue: disabled ? value : undefined,
            value: disabled ? undefined : value,
            onChange,
            onBlur,
            disabled,
            error:
                dirty && !valid
                    ? typeof invalidMessage === 'function'
                        ? invalidMessage(cleanedValue)
                        : invalidMessage
                    : undefined,
        },
        value: cleanedValue,
        setValue,
        valid,
        touched,
        setTouched,
        untouched,
        dirty,
        setDirty,
        pristine,
        reset,
    }
}
