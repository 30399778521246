import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Outside = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            <p>
                Bienvenue dans la page réservée aux <strong> grands espaces</strong>.
                <br /> <br />
                Voici des modèles construits à partir de relevés topographiques
                d'images satellite, de drones et de caméra 360°. Nous proposons des 
                <strong> mises en scène</strong>, car il s'agit bien de capter
                l'attention de l'internaute, d'offrir une expérience de navigation.
                <ul>
                    <li>
                        <a href='#a'>Jeu éducatif (serious game)</a>
                    </li>
                    <li>
                        <a href='#b'>Alternative de navigation internet</a>
                    </li>
                    <li>
                        <a href='#c'>3D ultra réaliste</a>
                    </li>
                </ul>
                <br /> <br />
                <h1 id='a'>Jeu éducatif (serious game)</h1>
                Ce premier modèle propose un jeu de plateau qui, de fil en aiguille, 
                vous conduira vers des <strong>endroits remarquables</strong>,  
                qui sont en réalité l'objectif de votre communication.
                <br /> <br />
                Quoi de mieux qu'un défi amical pour impliquer son interlocuteur ?
                <br />
                Trois coccinelles se cachent dans les maquettes, à vous de les trouver !
            </p>
            <Viewer id='beefffc84b2f485a802670e98424a52d' />
            <p>
                Vous avez pu découvrir le port Rhu de Douarnenez, bravo !
                <br />
                .    Avez-vous bien repéré le bateau phare et les 360° de l'artisanat local ? 
                <br />
                .    Vous êtes-vous déplacé de maquette en maquette, en suivant les annotations ?
                <br />
                .    Avez-vous aussi pu prendre la main sur le modèle, en double-cliquant sur une zone d'intérêt et en zoomant ?
                <br /> <br />
                Très bien !
                <br />
                Vous avez donc découvert comment sortir du mode automatique et vous avez gagné en autonomie ! 
                Et si vous êtes passés à coté des coccinelles, elles sont cachées dans les maquettes de Douarnenez, 
                dans l'atelier de voilerie et dans la voiture qui est garée sur le quai porte ouverte.
                <br />La troisième ? C'est le skipper de l'imoca de tête sur la carte de la Bretagne !
                <br /><br />
                Félicitations à ceux qui les ont découvertes !
                <br /> <br /> <br /> <br />
                Et voilà, vous venez d'expérimenter l'un de nos concepts de mise en scène. 
                <br /> <br /> 
                Parlons un peu technique maintenant. Il s'agit d'un mixte de techniques, qui présentent chacune leur intérêt:
                <br /> <br />
                <h3>Le <strong>3D "cartoon"</strong> </h3>
                Il permet de simplifier la lecture de  
                la scène, de faciliter la compréhension grâce aux animations, et de retenir  
                l'attention par son intéractivité et sa qualité graphique. Un tel modèle dépasse 
                rarement les 500€.
                <br /> <br />
                <h3>Le <strong>360°</strong> </h3>
                Vous avez ainsi accès à de l'information en haute   
                définition, une photo de la réalité. Toujours en mode interactif, 
                l'internaute prend la main à tout moment. Si vous avez la possibilité de réaliser 
                vos photos 360° vous même, une telle prestation dépasse aussi rarement les 500€, sinon,  
                il faudra compter la location de notre GoPro Max en supplément, quelques dizaines d'euros.
                <br /> <br />
                <h3>Le <strong>3D satellite</strong> </h3>
                Il permet de visualier les grands espaces en relief ! 
                Là aussi, nous sommes sous les 500€, et pour un modèle basique, sans retouche, la prestation 
                ne dépassera pas une centaine d'euros.
                <br /> <br />
                Les applications sont multiples, faire découvrir un territoire dans l'industrie du tourisme 
                ou simplement pour augmenter son attractivité, 
                faire découvrir un process de fabrication à ses clients, promouvoir son entreprise en interne 
                ou en communication publique.
                <br /> <br /> <br /> <br />
                Nos techniques de modélisation et de mise en scène sont en réalité si diverses 
                qu'il est difficile de proposer des modèles standards. Nous ne pouvons qu'effleurer 
                le champ des possibles.
                <br /> <br />
                Nous vous proposons de nous contacter pour en discuter, commencer à élaborer 
                ensemble une stratégie de communication, sans engagement de votre part. Les idées 
                viendront naturellement, cette phase de conception est très excitante, ce sera un 
                plaisir pour nous aussi,
                <br /> 
            </p>
                <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
                </Button>
            <p>
                <br /><br />
            </p>
                <h1 id='b'>Alternative de navigation internet</h1>
            <p>
                Ce modèle de carte satellite est en réalité une page de navigation internet, 
                avec des liens vers d'autres pages internet, c'est son but. Nous avons ajouté 
                des images pour augmenter l'expérience utilisateur, et nous apportons du contexte 
                aux informations. 
                <br /> <br />
                Cette notion de contexte est très importante, elle permet de garder 
                une grande cohérence dans votre communication.
                <br />
            </p>
            
            <Viewer id='872cf89cdc2e4baaa87f1002b959449c' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
                <br /> <br /> <br /> <br />
                <h1 id='c'>3D ultra réaliste</h1>
            <p>
                <br />
                Enfin, le modèle ci-dessous, réalisé avec un drone, offre une très belle 
                expérience de réalité virtuelle. Il vous faut néanmoins considérer que ce type de modèle 
                est très lourd à charger et, faute de très bonne connexion internet, le temps de 
                chargement pourra gêner la fluidité de navigation.
                <br /> <br />
                Le prix d'une telle prestation est aussi à prendre en compte, il faut du matériel 
                dédié et une prestation de prise de vue en plus de la constitution du modèle 3D.
            </p>
            <Viewer id='5fc87855b67b4d3ebba9b0166ffca0f3' autoplay={false} />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /> <br />
            </p>
            <Button
                color='secondary'
                to='/'
                className='beeseen-applications__example-back-button'
                component={Link}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
