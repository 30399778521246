import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousLitho = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>La lithophanie imprimée en 3D</h1>
            <p>
                La boule rétroéclairée au centre de la scène est une lithophanie, c'est un objet tout 
                à fait particulier, qui donne l'illusion d'une image grâce aux variations d'épaisseur 
                de la matière.
                <br /><br />
                Le résultat est bluffant, vous tenez dans votre main la scène à 360° !
                <br /><br />
                Nowlook vous propose de préparer vos fichiers pour une
                impression 3D et de vous mettre en contact avec un imprimeur 3D
                si vous n'avez pas d'imprimante de ce type.
                <br /><br />
                Il suffit de nous transférer vos photos et votre cadeau personalisé 
                est presque prêt. 
                <br />Vous avez en plus accès à la scène digitale et pouvez jouer entre les deux.
                <br />Avec le bouton gauche de votre souris appuyé, ou avec votre doigt, tournez la scène, n'avez-vous 
                pas l'impression d'imprimer au tampon encreur la place du village ?
                <br /><br />
            </p>
            <Viewer id='578e049d61fb4c10b1e10df1d7537342' />
            <p>  
                <br /><br />
                Voici quelques idées de mises en scène, des lithophanies à poser, à suspendre ou 
                à poser devant une fenêtre.
                <br />
            </p>
            <Viewer id='15250e36c53249268e45df09fbcbac8e' />
            <p>
            <br />En plein jour, la litho apparaît blanche, ce n'est que lorsqu'elle 
                est illuminée par l'arrière que le motif apparaît en surface, comme par magie.
                <br />Les endroits épais sont foncés, les endroits fins sont lumineux, et toute une gamme 
                d'épaisseurs forment des images en nuances de gris.
                <br /><br />Nous en avons photographié une pour vous en rendre compte, elle est dans l'annotation 
                No.5 ci-dessous avec le retroéclairage LED d'un smartphone. Pas d'image sans éclairage, la 
                boule est blanche et soudainement l'image apparaît en 3D, avec une grande précision.
                <br />
                Vous reconnaîssez aussi les monuments de Paris en guirlande, la Tour Eiffel, le Sacré-Cœur et Notre-Dame.
                <br />Posés dessous, la Statue de la Liberté de New-York et la Mosquée-Cathédrale de Cordoue et le village de Locronan.
                <br /><br />Il vous faut savoir que Google autorise gracieusement la reproduction à titre individuel de leurs images 
                street view, c'est une belle opportunité pour ceux qui n'ont pas de camera 360°.
                <br /><br />Il vous suffira donc de nous transmettre le lien vers la photo désirée pour que nous vous la préparions au 
                format litho imprimable en 3D.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
