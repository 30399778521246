import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousArchi_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Architectural Design</h1>
            <p>
                Here is a quick design study for a customer who wanted to install a stairwell with the 
                constraints that it has to be suspended to the upper floor of his apartment, due 
                to technical reasons.
                <br /> <br />
                We helped him communicate ideas with the agency in charge of accepting his project.
            </p>
            <Viewer id='848cd6ffafed401fb3160d2dba5aa48e' />
            <p>
                His demand was accepted, which makes us happy and proud.
                <br /> <br />
                May you have a real estate project, confirm your thougts in 3D, share ideas, tell us,
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
