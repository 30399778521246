import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaAnimations = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Les animations</h1>
            <p>
                Les animations sont un élément important de nos scènes, elles donnent du réalisme 
                et aident à se projeter dans l'histoire qui est comptée.
                <br /><br />
                Elles peuvent être simples, quelques oiseaux dans le ciel, ou beaucoup plus éloborées 
                comme dans le modèle ci-dessous, qui fait comprendre le flux des composants qui entrent dans le 
                magasin d'une usine, sont stokées dans un magasin automatisé pour être distribués au fil de la production. 
                Vous voyez une personnes qui gère ce flux entrant de bacs marron, et qui récupère ensuite les bacs verts 
                des produits finis, qui avaient été rapportés des lignes de fabrication par des robots mobiles.
                <br />
            </p>
            <Viewer id='639f23d72d0c45adb3520c06210cc26e' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
