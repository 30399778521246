import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Museum2 = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            
            <h1>Exposition temporaire</h1>
            <p>
                Nous vous présentons ici une sélection de nos modèles préférés
                <br />Contactez nous si vous souhaitez aussi mettre vos oeuvres en valeur, en bas de page !
                <br /> <br />
            </p>
            <Viewer id='922621b4e0dd499d817cd3a039664289' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='da64e56526dd49acbda7cd8e07b7d7f3' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='4bc5ec09f5d546afb36b149e5bb5873b' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='d8d676cbfea44a2db2569fabc4ed6562' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='76277769396640f482fbeafa6ace76f4' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='a4193867eee64962aee21d557d2b038e' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='139bb018c01145e697489aeb838a7bce' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='ceb1fb93df26436ebc1587af0a401ddd' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='78cb10c4c17b481fbc3686f180422bb1' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='38a16ac196324620906f7944cf858060' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='2b9c4910c2044441a4a06b01ac43dddf' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='53c260bbb42a483ab0c0d7173d058ce7' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='9ea146578f09460a90a4e2b86882bfb0' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='02070d797f484d539432bff768789eaf' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='d079d4de500e4578b2b56dfc2c38d233' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='31c5ecf636d24d0e85969e925b261c30' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='92bfd8b4a863400995134761fba73f38' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='a1637020eeea4033a5ec611b50cbf045' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='b3be9327b06e47e98389e800a9b82508' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='3a6ba390d97d4704b553dced9d533264' />
            <p>
                <br /> <br />
            </p>
            <Viewer id='6830e60d2c1048f2a33e92679664f652' />
            <p>
                <br /> <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /> <br />
            </p>
            <Button
                color='secondary'
                to='/'
                className='beeseen-applications__example-back-button'
                component={Link}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
