import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EntreprisesImplant_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            
            <h1>Layout Simulation</h1>
            <p>
                Cette étape de conception des bâtiments, de la mise en place d'un parc de machines, de rayonnages  
                ou de sa mise à jour permet d'optimiser les coûts de lancement, car les erreurs sont détectées en 
                amont.
                <br />La visualisation en 3D offre en effet une impression réaliste de l'état futur, et les corrections à ce stade 
                sont financièrement négligeables.
                <br /> <br />
                <h3>Modélisation des équipements</h3>
                Ce type de maquette permet aux interlocuteurs de concevoir la meilleure implantation, et elle 
                permet aussi d'impliquer des cercles de personnes autour de l'équipe de conception, en particulier les utilisateurs.
                <br />Ce faisant, les résistances aux changements qui apparaissent, parfois de manière inconsciente, se trouvent 
                naturellement balayées, et le projet prend vie facilement, et avec enthousiasme.
                <br /><br />
                Prenons l'exemple ci-dessous.
                <br />Cette simulation d'implantation avait pour objectif d'optimiser la surface au sol et le rendement 
                d'une nouvelle usine de fabrication de pièces dans un pays à bas coûts de main d'oeuvre. La production est assurée par 
                des opérateurs qui pilotent des équipements semi-automatisés.
                <br />Le cahier des charges prévoyait la possibilité d'augmenter la capacité de production de l'usine, ce qui a été fait 
                en plaçant les zones de production en alignement avec leurs magasins de stockage de composants et de produits finis.
                <br />Vous pouvez remarquer cinq zones de production indépendantes. 
                <br /><br />
                Ainsi, l'usine peut s'étendre dans les deux axes, coté magasin par une symétrie ou coté arrière en ajoutant des zones magasin 
                et lignes de production. Voici d'ailleurs une
                <a href="https://sketchfab.com/models/f6920eb6bcf241248b3b23077ba47ea5/embed?autostart=0&amp"> étude d'extension </a>
                pour en comprendre l'idée.
                <br /> 
            </p>
            <Viewer id='6f75d0ffb5a44d9fb596c9c3a7d5724c' />
            <p>
                <br />
                Les éléments vérifiés lors de cette simulation statique d'implantation sont les suivants :
                <br />a) La largeur des allées pour les engins de manutension dans le magasin,
                <br />b) La largeur des allées pour les robots mobiles dans l'atelier de production,
                <br />c) Les accès au personnel de production sans gêner les flux de pièces, dans notre cas en transversal, 
                <br />d) Les conditions de travail en équipe, qu'aucun travailleur ne se sente isolé à l'intérieur de son unité 
                de production, et que chacun puisse interagir avec ses collègues aux alentours,
                <br />d) La disposition des bureaux pour supporter la production.
                <br /><br />
                <h3>Modélisation des flux</h3>
                Le 4D donne vie à vos maquettes, en montrant les flux physiques de vos process, mais aussi les flux immatériels.
                <br />Cette maquette permet de comprendre très rapidement les flux dans l'atelier et dans le magasin, il 
                vous suffit de naviguer à l'intérieur (pour accéder à une zone particulière, double cliquer et zoomer).
                <br />La vertue de ces animations résident en réalité dans leur process de mise en place. Elles catalysent les idées 
                et remarques, et permettent d'affiner les flux au fur et à mesure des discussions, conduisant in fine à une organisation 
                optimisée, source de profit.
                <br/>La constitution d'un 4D n'est qu'un pretexte pour mettre les humains en relation et en extraire le meilleur.
                <br /><br />
                Elle permet aussi, bien sûr, de vérifier qu'il n'y ait aucune interférence et que "tout passe", même dans les endroits 
                réduits ou à grande fréquentation.
                <br />Nous avons aussi agrémenté la maquette d'une animation textuelle pour comprendre l'organisation générale, c'est un 
                bénéfice collatéral de ce projet, pour une opération de communication en interne.
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
