import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace3Dsat_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Satellite 3D</h1>
            <p>
                We take satellite pictures and elevation maps to draw such models.
                <br /> <br />
                We prepared below model like an internet page, it's another way to navigate, it's visual, provide 
                context, it's intuitive, you fly between the information.
                <br />
            </p>
            <Viewer id='872cf89cdc2e4baaa87f1002b959449c' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
