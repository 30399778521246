import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousLitho_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Lithophane printed in 3D</h1>
            <p>
                In Nowlook, we aim to distribute 360° photos in the form of physical 3D lithophanes, 
                3D printed, a modern process for an artwork that was developed with very thin translucent 
                porcelain in earnest at the beginning of the 19th century. In reality, we want to connect 
                digital and real worlds to have people who are not familiar with 3D or internet adhering 
                to digital technologies.
                <br /><br />
                When it’s 3D printed, a lithophane is white with reliefs, it’s only when you illuminate 
                it from the inside that your photo appears, with a high degree of accuracy.
                <br /><br />
                It’s impressive, when you turn the light on, your family, cat or dog, souvenir of your 
                holidays, retired colleagues, sports team, etc. appears.
                <br /><br />
                We propose to prepare the file ready for 3D printing, and put you in contact with a 
                printing company if you don't have your own printer. Send us your photos and your personalized 
                gift is almost ready.
                <br /><br />
                On top of the physical object, we grant you access to the corresponding 3D digital model, it's 
                the opportunitty to play between reality and digital, litho levitating in its scene.
                <br />With your finger or mouse buttons, rotate the scene below, isn't it surprizing, you feel 
                like printing the real scene around with your litho, like with a rolling inkpad.
                <br /><br />
            </p>
            <Viewer id='578e049d61fb4c10b1e10df1d7537342' />
            <p>  
                <br /><br /><br /><br />
                Find here below some ideas of staging.
                <br />You'll observe that in broad daylight, litho is white, it's only with a backlight 
                that texture appears at surface, as if by magic, dark in thick areas, bright is thin areas, and 
                gray scale between.
                <br /><br />To make you feel the effect, we took a photo in annotation No.5, in that case, a LED 
                backlight is provided by a smartphone.
                <br /><br />
                Also, we offer you to prepare 360° images from Google Street View for 3D printing. 
                Google accepts this practice for personal use
                <br />As examples, see in guirland Paris momuments Eiffel Tower and Notre-Dame, a panoramic view 
                of Paris from the Sacré Coeur and on table 
                the Statue of Liberty and the Great Mosque of Córdoba. 
                <br />
            </p>
            <Viewer id='2ed26a2d01f14fc48bdf0fea765fe028' />
                        <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
