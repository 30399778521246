import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Entreprises40 = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
        
            {/* <iframe 
                    width="100%"
                    height="600"
                    src="https://nowlook.fr/static/FoF/"/>

            <p> */}
        

        {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf58Pm8e-O_cwgaSc5c5_kxVwgxD7LTKHzcVOEU6lkS9HhZSw/viewform?embedded=true" width="640" height="1170" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}

            <h1>Les jumeaux numériques</h1>
            <p>
                Un jumeau numérique est une représentation de vos équipements et process industriels qui 
                offre des informations dans leurs contextes.
                <br /><br />En voici des exemples :
                <br />
            </p>
            <Viewer id='d9f747bf15d744aabdc56eae45ddb9ef' />
            <br /><br />
            {/* <Viewer id='2c4c8bdc54e14ddd9ac631a7cb5d225c' /> */}
            <Viewer id='2c4c8bdc54e14ddd9ac631a7cb5d225c' />  {/* Station avec interactivité */}
            <br /><br />
            <h2>17 fonctionnalités !</h2>
            <p>
                Nous réalisons actuellement une enquête sur la pertinence des fonctionnalités que peut 
                offrir un jumeau numérique. Pour participer vous aussi, utilisez le lien suivant : 
                <br />https://forms.gle/TmxupKMG3mQMCwoy7
                <br /><br />
                Nous comptons sur vous pour améliorer nos applications en cours de développement !
                <br /><br />
            </p>
            {/* <h3>Résultats intermédiaires</h3>
            <p>
                Les premiers retours de l'enquête font apparaitre les besoins prioritaires suivants :
                <br />- Création et mise à jour du modèle 3D en toute autonomie, sans compétence en infographie,
                <br />- Il faut du réalisme et il faut pouvoir partager facilement son jumeau numérique,
                <br />- Et enfin, il faut pouvoir l'utiliser dans la vie de l'atelier comme pivot central 
                de la communication entre les hommes.
                <br /><br />
                D'autres besoins sont identifiés mais ne sont pas prioritaires :
                <br />- La connexion aux données en temps réel n'est pas plébiscitée car les bases de 
                données sont encore aujourd'hui insuffisamment structurées ou en silos,
                <br />- Les simulations de flux, quant à elles, demandent un investissement significatif en 
                temps de mise en place,
                <br />- Par contre, quelques animations peuvent aider à la compréhension rapide des process, 
                <br />- Le contrôle du monde réel par l'intermédiaire du jumeau numérique viendra plus tard, 
                avec la maturité numérique de l'entreprise.
                <br /><br />
            </p> */}
            {/* <Viewer id='881363867c694fafbfb3d8b090495d3f' /> */}
            <Viewer id='8d1a39a3a738431393b5a09a3600e8bb' />
            <br />
            {/* <h1>Critères d'évaluation</h1>
            <h2>A. Fonctionnalités de base</h2>
            <h3>A1. Modélisation 3D</h3>
            <p>    Gérer ses infrastructures en 3D, les espaces (murs, cloisons, racks, etc.) et la bibliothèque 
                d'équipements (machines, outillages de manutention, tuyauterie, etc.).
            </p>
            <h3>A2. Analyse des distances et interférences</h3>
            <p>    Ajuster la position de ses équipements par une visualisation interactive des distances, 
                avec plusieurs configurations de matériels, portes ouvertes et fermées, par exemple, ou 
                par disposition d’un mannequin pour vérifier visuellement et en 3D l’ergonomie d’un poste.
            </p>
            <h3>A3. Visite Virtuelle à 360°</h3>
            <p>    Offrir une visite virtuelle à 360° en complément du 3D pour les industriels possédant une 
                caméra 360 de type GoPro.
            </p>
            <h3>A4. Autonomie</h3>
            <p>    Pouvoir réaliser et mettre ses modèles à jour à tout moment sans compétence particulière 
                de logiciel CAO ou infographie.
                <br /><br /><br />
            </p>
            <h2>B. Fonctionnalités d'exploitation terrain</h2>
            <h3>B1. Visualisation de données</h3>
            <p>    Le modèle 3D visualise des données importées (qualité, sécurité, 5S, maintenance, finance, 
                développement durable, etc.) pour une identification multicritère et rapide des zones 
                d’attention prioritaires. La maquette numérique devient le rapport d'audit, visuel, 
                contextuel et interactif.
            </p>
            <h3>B2. Prise de données</h3>
            <p>    Les données sont prises à partir du jumeau numérique pour contextualiser l'information et 
                éviter les déclarations textuelles classiques. L'application génère le questionnaire 
                interactif selon le contexte. Les retards de planning d'audit sont visualisés sur le 3D.
            </p>
            <h3>B3. Données en temps réel</h3>
            <p>    Le modèle 3D visualise les données et dépassements de seuil (Capteurs IoT de température, 
                HR%, vibrations, sons, ouvertures de porte, énergies, etc.). Le flux de données est établi 
                à partir d'application tiers.
            </p>
            <h3>B4. Collaboration</h3>
            <p>    Le modèle 3D et/ou 360° est utilisé pour tracer les évènements et plans d'actions grâce à 
                des annotations apposées sur la zone considérée du jumeau numérique avec un code visuel 
                de type et d'importance.
            </p>
            <h3>B5. Alerte sur les annotations</h3>
            <p>    L'application transmet un sms ou un mail au référent métier en parallèle de la visualisation 
                dans le jumeau numérique.
            </p>
            <h3>B6. Simulation de flux</h3>
            <p>    Les flux sont simulés en paramétrant chaque élément du système et en laissant l'application 
                tourner selon les lois physiques mises en place. L'application propose le meilleur scénario 
                de réaction immédiate en cas d'aléas pour limiter les impacts néfastes qui pourraient probablement arriver. 
                <br /><br /><br />
            </p>
            <h2>C. Fonctionnalités graphiques</h2>
            <h3>C1. Animations</h3>
            <p>    Animer le modèle 3D pour visualiser les flux ou les séquences d’un process, par exemple.
            </p>
            <h3>C2. Réalisme</h3>
            <p>    Texturer ses équipements 3D pour rendre sa maquette réaliste et permettre de se déplacer dans 
                l’usine « comme si vous y étiez ». Les textures sont issues de photos prises à partir de 
                smartphone et corrigées automatiquement dans l’application, en complète autonomie et facilement.
            </p>
            <h3>C3. Liens internet</h3>
            <p>    Ajouter des liens vers des pages web, des rapports Power BI ou d’autres modèles 3D, de machines 
                animées par exemple, pour n’avoir qu’une source d’informations fiable et des informations replacées 
                dans leur contexte. La navigation intuitive dans le 3D remplace la navigation linéaire d’internet 
                ou des systèmes de répertoires One Drive ou Teams.
            </p>
            <h3>C4. Partage</h3>
            <p>    Partager son modèle 3D avec un lien internet qui permet aux personnes destinataires de voir votre 
                établissement en vue d'oiseau ou en se déplaçant en marchant virtuellement à l’intérieur de la maquette.
            </p>
            <h3>C5. Géo-localisation</h3>
            <p>    Géolocaliser ses assets à partir de positions importées d'une application tiers, de tags UWB par exemple.
                <br /><br /><br />
            </p>
            <h2>D. Fonctionnalités de contrôle</h2>
            <h3>D1. Paramétrage</h3>
            <p>    Modifier les paramètres process, les réglages de capteur etc. depuis le jumeau numérique.
            </p>
            <h3>D2. Actionnement</h3>
            <p>    Déclencher des actions à distance, à partir du modèle 3D.
            </p> */}

            <br /><br />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /> <br />
            </p>
            <Button
                color='secondary'
                to='/'
                className='beeseen-applications__example-back-button'
                component={Link}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
