import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaGraphisme_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Graphic Design</h1>
            <p>
                The model below was hand painted, textures arranged on 3D surfaces.
                <br /><br />
                In a way, it's equivalent to the complex textures of 360° photos, in a cartoon shaping.
                <br /><br />
                It's artistic creation, and it has a cost in case of complex painting, but 
                consider some graphic design in any project, even little, because we observed 
                that user experiment with a pleasant navigation is key in 4D communication, 
                even internal, not just for marketing.
                <br />
            </p>
            <Viewer id='94b24a60dc1b48248de50bf087c0f042' />
            <p>
                Designed by Glen Fox, @glenatron87
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
