import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EspaceCartoon = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le mode cartoon</h1>
            <p>
                Le mode cartoon est un système de représentation simplifié de la réalité, avec un graphisme 
                "à facettes"
                <br /><br />
                Il permet des jeux d'ombres et de lumières, des reflets, des transparences, qui vous transportent dans un monde 
                virtuel reflet de la réalité.
                <br /><br />
                Nous utilisons le mode cartoon pour simplifier la communication, pour aller à l'essentiel, focaliser sur les 
                points d'intérêt, en hyper zoom ou en vue d'oiseau, dans les zones visibles ou les endroits cachés.
                <br /><br />
                La composition de ce type de modèle doit idéalement provoquer la surprise et l'envie de poursuivre la découverte. 
                Cela n'a rien de simpliste, bien au contraire, il doit être bien pensé pour provoquer l'émotion.
                <br /><br />
                Prenez la main, bougez avec votre doigt ou le bouton gauche de votre souris enfoncé.
                <br />Double-cliquez sur une zone pour vous téléporter et zoomez.
                <br />En vous promenant dans la maquette, vous trouverez un cormoran ailes déployées au soleil, 
                des poissons stylisés frayant autour du bateau-phare, une coccinelle en vol dans une voiture, 
                des pêcheurs en grande discussion sur le ponton.
                <br />
            </p>
            <Viewer id='36c729380029423a9a401c4e59694493' />
            <p>
                L'intérêt tient inconsciemment dans le désir qu'a l'être humain de maîtriser son environnement, de 
                se déplacer comme un oiseau, de traverser la matière, d'être libre. 
                <br /><br />
                Depuis notre plus jeune âge, nous aimons ces petits mondes à taille réduite,
                <br />nous reproduisons ces sensations profondes avec les outils d'aujourd'hui.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/#applications'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
