import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaPhysique = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le monde physique</h1>
            <p>
                Pourquoi parler de création de valeur dans le monde physique alors que nous 
                prônons le monde digital ?
                <br /><br />
                Et bien, simplement, nous ne sommes pas tous égaux face au monde digital, 
                la très grande majorité des personnes est très loin de l'univers des jeux vidéo, 
                et la navigation sur internet leur est difficile.
                <br /><br />
                Nous souhaitons les embarquer dans notre aventure, les amener gentillement dans les 
                univers virtuels qui aportent tant de satisfaction.
                <br /><br />
                Voici notre stratégie,
                    <li>
                        Nous proposons d'acquérir des objets réels qui représentent une scène à 360°. 
                        C'est un objet physique, il est imprimé en 3D, mais il est aussi virtuel parceque 
                        lié à un modèle 360° d'une part, et qu'il n'apparaît qu'avec un rétroéclairage 
                        d'autre part.
                    </li>
                    <li>
                        En parallèle, nous mettons le modèle digital de l'impression 3D à disposition, 
                        pour provoquer une connection naturelle entre l'objet physique et ce qui est montré 
                        à l'écran, afin d'inciter à aller plus loin dans l'investigation des mondes virtuels 
                        que nous offrons. Le modèle ci-dessous présente la place du village de 
                        Locronan, à titre d'exemple.
                    </li>
                <br />
            </p>
            <Viewer id='578e049d61fb4c10b1e10df1d7537342' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
