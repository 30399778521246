import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import AlternateEmail from '@material-ui/icons/AlternateEmail'
import Email from '@material-ui/icons/Email'
import Face from '@material-ui/icons/Face'
import Message from '@material-ui/icons/Message'
import Phone from '@material-ui/icons/Phone'
import Send from '@material-ui/icons/Send'
import firebase from 'firebase/app'
import React, { ChangeEvent, memo, useState } from 'react'
import { db } from '../database'
import './index.scss'
import { useTextField } from './text-field'

const validEmailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const validateEmail = (email: string) =>
    validEmailRegExp.test(email.toLowerCase())

export const Contact = memo(() => {
    const [sent, setSent] = useState(false)
    const [consent, setConsent] = useState(false)
    const onChangeConsent = (event: ChangeEvent<HTMLInputElement>) =>
        setConsent(event.target.checked)
    const {
        value: email,
        valid: emailValid,
        properties: emailProperties,
    } = useTextField({
        isValid: validateEmail,
        invalidMessage: 'Format invalide',
    })
    const {
        value: name,
        valid: nameValid,
        properties: nameProperties,
    } = useTextField({
        isValid: (name) => name.length > 0,
        invalidMessage: 'Required',
    })
    const {
        value: message,
        valid: messageValid,
        properties: messageProperties,
    } = useTextField({
        isValid: (message) => message.length > 0,
        invalidMessage: 'Required',
    })
    const {
        value: phone,
        valid: phoneValid,
        properties: phoneProperties,
    } = useTextField({
        isValid: (phone) => phone.length > 0,
        invalidMessage: 'Required',
    })
    const valid =
        nameValid && emailValid && messageValid && phoneValid && consent
    const send = () => {
        const user = firebase.auth().currentUser
        setSent(true)
        if (user) {
            db.collection('contacts')
                .doc(user.uid)
                .set({
                    email,
                    name,
                    message,
                    phone,
                    createdAt: Date.now(),
                })
        }
    }
    return (
        <>
            <div className='jump' id='contact' />
            <div className='beeseen-block beeseen-contact'>
                <div className='beeseen-block__content beeseen-contact__content'>
                    <h2>
                        <Email
                            fontSize='inherit'
                            className='beeseen-contact__icon'
                        />
                        Contactez-nous / Contact us
                    </h2>
                    {sent ? (
                        <div className='beeseen-contact__form-sent'>
                            Merci, nous allons revenir vers vous au plus vite ! / Thank you, we revert to you asap!
                        </div>
                    ) : (
                        <div className='beeseen-contact__form'>
                            <div className='beeseen-contact__form-name'>
                                <TextField
                                    label='Nom / Name'
                                    margin='normal'
                                    fullWidth={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Face />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...nameProperties}
                                    error={!!nameProperties.error}
                                    helperText={nameProperties.error}
                                />
                            </div>
                            <div className='beeseen-contact__form-email'>
                                <TextField
                                    label='Email'
                                    margin='normal'
                                    fullWidth={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <AlternateEmail />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...emailProperties}
                                    error={!!emailProperties.error}
                                    helperText={emailProperties.error}
                                />
                            </div>
                            <div className='beeseen-contact__form-message'>
                                <TextField
                                    label='Message'
                                    margin='normal'
                                    fullWidth={true}
                                    multiline={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Message />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...messageProperties}
                                    error={!!messageProperties.error}
                                    helperText={
                                        messageProperties.error ||
                                        'Parlez nous de vos projects / Tell us more about your project'
                                    }
                                />
                            </div>
                            <div className='beeseen-contact__form-phone'>
                                <TextField
                                    label='Téléphone / Phone'
                                    margin='normal'
                                    fullWidth={true}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <Phone />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...phoneProperties}
                                    error={!!phoneProperties.error}
                                    helperText={phoneProperties.error}
                                />
                            </div>
                            {/* <div className='beeseen-contact__form-consent'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            onChange={onChangeConsent}
                                        />
                                    }
                                    label={`J'accepte de recevoir des communications marketing de la part de NowLook.`}
                                    className='beeseen-contact__form-consent-checkbox'
                                />
                                <p>
                                    En cliquant sur le bouton ci-dessous, vous
                                    acceptez que NowLook enregistre et traite
                                    les informations soumises
                                    ci-dessus.
                                    <br />
                                    Vous pouvez vous désabonner de ces
                                    communications à tout moment.
                                </p>
                            </div> */}
                            <div className='beeseen-contact__form-send'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    endIcon={<Send />}
                                    onClick={send}
                                    disabled={!valid}
                                >
                                    Envoyer / send
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
})
