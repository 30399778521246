import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousMeditation_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Meditation in Virtual Reality</h1>
            <p>
                One type of meditation involves concentrating on something, such as your 
                breathing with a candle flame. We propose an alternative support with 360° 
                relaxing environement, peaceful landscape with ambient sound and music to 
                reinforce immersion.
                <br /><br />
                Before you close your eyes, allow your mind to relax and wander in our moving scene, 
                like facing a candle, gently breathing. Make yourself a trial, you'll get the idea.
                <br />
            </p>
            <Viewer id='0755e61c590044e0804f30a35d95c77f' />
            
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
