import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaPhysique_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Physical World</h1>
            <p>
                Why this page speaking physics when we promote digital as a standard in human communication?
                <br /><br />
                Well, nor do we stand on an equal footing as we face digital world, the vast majority of us 
                are far from internet gaming, even web nagivation.
                <br /><br />
                We target to embark them on the adventure of virtual reality, gently connect.
                <br /><br />
                Here is our strategy,
                    <li>
                        We propose to acquire real objects presenting a 360° scene. It's physical, 3D printed, 
                        but it's also virtual because it's linked to a 360 model and it appears only with backlight
                    </li>
                    <li>
                        In parallel, we offer the digital model of what was printed. Our goal is to produce a 
                        natural connection between the physical object and what's shown in screen, and then 
                        incite web user to pursue investigation of virtual worlds. Below model presents a 360° of 
                        Locronan, in France.
                    </li>
                <br />
            </p>
            <Viewer id='578e049d61fb4c10b1e10df1d7537342' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
