import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaGraphisme = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le graphisme</h1>
            <p>
                Le modèle ci-dessous a été peint à la main, les textures agencées 
                sur les surfaces en 3D.
                <br /><br />
                C'est en quelque sorte un équivalent des textures complexes des photos 360° 
                avec une mise en situation sur un modèle cartoon.
                <br /><br />
                Il s'agit d'un vraie création artistique, cela a un coût si le modèle est 
                complexe mais il faut considérer une partie graphique dans tout projet, même 
                légère, car nous avons constaté que le plaisir de la navigation était un 
                élément clé de la communication 4D. L'expérience de navigation se doit 
                d'être plaisante, même pour des applications internes, la communication n'est pas 
                réservée qu'au marketing.
                <br />
            </p>
            <Viewer id='94b24a60dc1b48248de50bf087c0f042' />
            <p>
                Oeuvre de Glen Fox, @glenatron87
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
