import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace3Drealiste_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Realistic 3D</h1>
            <p>
                When a scene does not exist, it's rather convenient to build it from scratch and we can make it.
                <br /><br />
                Below model presents a booth in an exhibition.
                <br />
            </p>
            <Viewer id='68e1e3052eef45bb9c1419d433e5933c' />
            <p>
                Special care is required in texturing and staging, in order to project web user into scene and feel like real.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
