import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const SmallSpaces = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            <p>
                Bienvenue dans la page réservée aux{' '}
                <strong> objets et aux petits espaces</strong>.
                <ul>
                    <li>
                        <a href='#a'>Préparation d'impressions 3D</a>
                    </li>
                    <li>
                        <a href='#b'>Visite virtuelle</a>
                    </li>
                    <li>
                        <a href='#c'>Etude d'implantation</a>
                    </li>
                </ul>
                <br /><br /><br />
            </p>
            <h1 id='a'>Préparation d'impressions 3D</h1>
            <p>
                Voici une création très particulière, une boule rétroéclairée
                qui donne l'illusion d'une image grâce aux variations
                d'épaisseur de la matière.
                <br /><br />
                C'est une lithophanie, un objet tout à fait particulier, le
                résultat est <strong>bluffant</strong>.
                <br />
            </p>
            <Viewer id='578e049d61fb4c10b1e10df1d7537342' />
            <p>
                Nowlook vous propose de préparer vos fichiers pour une
                impression 3D et de vous mettre en contact avec un imprimeur 3D
                si vous n'avez pas d'imprimante de ce type.
                <br /><br />
                Il suffit de nous transférer vos photos et votre cadeau
                personalisé est presque prêt.
                <br />
                Voici quelques idées de mises en scène, des lithophanies à poser, à suspendre ou 
                à poser devant une fenêtre.
                <br /><br />En plein jour, la litho apparaît blanche, ce n'est que lorsqu'elle 
                est illuminées par l'arrière que le motif apparaît en surface, comme par magie.
                <br />Les endroits épais sont foncés, les endroits fins sont lumineux, et toute une gamme 
                d'épaisseurs forment des images en nuances de gris.
                <br /><br />Nous avons photographié la litho 3D de Locronan dans l'annotation No.5 ci-dessous 
                avec le retroéclairage LED d'un smartphone, l'effet est garanti, pas d'image sans éclairage, et 
                soudainement l'image apparaît en 3D, avec une grande précision.
                <br />
            </p>
            <Viewer id='15250e36c53249268e45df09fbcbac8e' />
            <p>
                Vous reconnaîssez les monuments de Paris en guirlande, la Tour Eiffel, le Sacré-Cœur et Notre-Dame.
                <br />Posées dessous, la Statue de la Liberté de New-York et la Mosquée-Cathédrale de Cordoue et le village de Locronan.
                <br /><br />Il vous faut savoir que Google autorise gracieusement la reproduction à titre individuel de leurs images 
                street view, c'est une belle opportunité pour ceux qui n'ont pas de camera 360.
                <br /><br />Il vous suffira donc de nous transmettre le lien vers la photo désirée pour que nous vous la préparions au 
                format litho imprimable en 3D.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /><br /><br />
            </p>
                <h1 id='b'>Visite virtuelle</h1>
            <p>
                Les visites virtuelles sont maintenant incontournables dans
                l'immobilier, tellement la capacité de se projeter dans l'image
                est puissante.
            </p>
 
            <Viewer id='de09cba78416448f81263128de0fdd5f' />
            <p>
                Notre startup a apporté quelques innovations
                <br />
                <br />- un plan en 3D, qui permet de se repérer dans les lieux,
                avec une vue simplifiée des locaux pour une meilleure
                compréhension, la matérialisation des espaces, et une vue
                satellite de la zone, en 3D, pour se projeter réellement dans
                l'espace.
                <br />
                <br />- la possibilité d'animer le modèle, d'y mettre de la vie,
                avec un feu de cheminée, un écran qui diffuse du contenu, ou,
                pour une entreprise, des pages d'information, la matérialisation
                de vos flux physiques ou immatériels sur une superposition du
                réel.
                <br /> <br /><br /><br /><br />
                Pour faire court, nous vous permettons de vous différentier et
                de provoquer un intérêt supplémentaire, d'apporter du contexte
                et de l'information, un contenu de qualité.
                <br /> <br />
                Que vous soyez un industriel ou un particulier, ces{' '}
                <strong>360 augmentés</strong> ont un très fort impact,
                profitez-en !
                <br /><br />
                Voici une variante de mise en forme pour mettre votre 
                marque en valeur en plus de présenter vos installations. Imaginez vos bureaux par exemple.
            </p>
            <Viewer id='193108cfa63d49b1913f1061533ee959' />
            <p>
                L'imagination est la seule limite pour bâtir une communication
                4D impactante, nous avons pour principe de travailler en étroite
                collaboration avec nos clients pour obtenir le meilleur résultat
                dans le budget imparti.
                <br /><br />
                Notre volonté est de démocratiser l'utilisation de la 3D et de permettre à nos clients d'être{' '}
                <strong>autonomes</strong> pour la réalisation de leurs modèles.
                <br /><br />
                Un budget d'environ 450€ vous permet d'acquérir une caméra 360° telle que la nôtre (une GoPro Max de 17 mpx), 
                c'est un investissement immédiatement amorti dans le cadre d'une vente immobilère. 
                <br /><br />
                Envoyez-nous vos photos, nous nous occupons du reste,
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /><br /><br />
            </p>
            <p>
            <h1 id='c'>Etude d'implantation</h1>
            </p>
            <p>
                Voici une étude rapide réalisée pour un client qui souhaitait
                comprendre comment intégrer un escalier dans son loft.
                <br />
                <br />
                L'étude technique montrait une faiblesse de tenue du plancher,
                il fallait suspendre l'escalier.
                <br /> <br />
                Nous l'avons aidé à communiquer ses idées avec l'architecte de son syndic.
            </p>
            <Viewer id='848cd6ffafed401fb3160d2dba5aa48e' />
            <p>
                Son dossier a été accepté, nous en sommes heureux et fiers.
                <br /> <br />
                Vous avez un projet immobilier, ou le besoin de visualiser votre projet en 3 dimensions,
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br />
            </p>
            <Button
                color='secondary'
                to='/'
                className='beeseen-applications__example-back-button'
                component={Link}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
