import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

firebase.initializeApp({
    apiKey: 'AIzaSyCNWVEF-KYUji2EnRbUyx4sEI-aB6fQV4M',
    authDomain: 'nowlook-project.firebaseapp.com',
    databaseURL: 'https://nowlook-project.firebaseio.com',
    projectId: 'nowlook-project',
    storageBucket: 'nowlook-project.appspot.com',
    messagingSenderId: '663112899674',
    appId: '1:663112899674:web:7b190b3aa57521abaa4c63',
})

firebase.auth().signInAnonymously()

export const db = firebase.firestore()
