import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EntreprisesCollab_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Collaboration Spaces</h1>
            <p>
                The intention here is to provide a feeling to belong to a team at distance, workers with zoom fatigue who may feel 
                isolated and disconnected.
                <br />Together in team work, furniture is chosen, displays, etc. It's this process to build its team "war 
                room" that produces interactions between members, make decisions together and finally engage. The result is visual 
                and used during the project lifetime, recalling the rules of engagement.
                <br />
                <br />The second benefit is about communication. 
                <br />At the end of each meeting in the virtual room, in agile mode for exemple, as proposed in the Trello board 
                when closing the door, last update is displayed.
                <br />By the way, management and also teams around access to project staus if access rights allow.
            </p>
            <Viewer id='b90cbfc37b2147a6af1ac17dac012ed7' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
