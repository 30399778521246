import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import React, { memo } from 'react'
import './index.scss'

export const Tools = memo(() => {
    return (
        <>
            <div className='jump' id='tools' />
            <div className='beeseen-block beeseen-tools'>
                <div className='beeseen-block__content'>
                    <h2>
                        <SupervisorAccount className='beeseen-tools__title-icon' />
                        Nos partenaires
                    </h2>
                    <p>
                        <strong>La société Epic Games</strong> nous permet de mettre à disposition de nos clients
                        la puissance de leur moteur de jeu vidéo, gratuitement. Merci à eux !<br />
                        <a href='https://www.epicgames.com/site/fr/home?lang=fr&sessionInvalidated=true'>
                            EpicGames.com
                        </a>
                    </p>
                </div>
            </div>
        </>
    )
})
