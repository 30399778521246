import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Immo1 = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Visite à 360°</h1>
            <p>
                Nos visites sont un peu particulières.
                <br />Il nous semble que les visites à 360° que nous connaissons classiquement manquent 
                de contexte, passant d'une pièce à l'autre en se perdant dans les espaces.
                <br /><br />
                Nous proposons une <strong>visite virtuelle</strong> avec un plan qui visualise 
                les espaces, avec les murs et les fenêtres, et qui replace le logement dans son quartier.
                <br /><br />
                Ainsi, tout est disponible dans le même modèle. Une animation complète le service que 
                nous offrons, passant d'une annotation à l'autre si vous laissez faire. Vous pouvez prendre 
                la main à tout moment bien sûr, ce n'est qu'une option, qui est bien pratique pour les personnes 
                qui ne sont pas habituées à ce type de navigation.
            </p>
                <Viewer id='de09cba78416448f81263128de0fdd5f' />
            <p>
                    Avec nous, c'est très simple, vous nous transférez vos photos à 360° et nous vous renvoyons 
                    un lien que vous pouvez intégrer à un site internet ou que vous pouvez partager à vos potentiels 
                    acquéreurs ou loueurs.
                    <br /><br />
                    Ce type de mise en scène est aussi très apprécié pour des locations de salle, d'évennementiel, 
                    de visite de sites naturels ou industriels, de magasins, etc. 
                    <br />Nous vous offrons ce service, une minimap (la carte du lieu) et autre astuces pour mettre 
                    vos services en valeur.
                    <br /><br />
            </p>
                <Viewer id='8799470aa93c466199e86294cb55f12f' />
            <p>
                    Contactez-nous, ce type de visite est une vraie valeur ajoutée pour vos ventes 
                    ou locations immobilères.
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br />  </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
