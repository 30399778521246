import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace3Dsat = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le 3D satellite</h1>
            <p>
                Pour constituer ce type de modèle, nous récupérons des images satellites ainsi que les élévations. 
                <br />Le paysage est reconstitué en 3D, et vous comprenez mieux ou vous êtes projetés. 
                <br /> <br />
                Nous avons préparé ce modèle ci-dessous comme une page internet, une autre manière de vous déplacer 
                parmi les sources d'information, physiquement en quelque sorte, comme un oiseau.
                <br />
            </p>
            <Viewer id='872cf89cdc2e4baaa87f1002b959449c' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
