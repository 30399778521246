import Button from '@material-ui/core/Button'
import Category from '@material-ui/icons/Category'
import EmojiTransportation from '@material-ui/icons/EmojiTransportation'
import Healing from '@material-ui/icons/Healing'
import HomeWork from '@material-ui/icons/HomeWork'
import LocalFlorist from '@material-ui/icons/LocalFlorist'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../viewer'
import './index.scss'

export const Applications = memo(() => {
    return (
        <>
            <div className='jump' id='applications' />
            <div className='beeseen-block beeseen-applications'>
                <div className='beeseen-block__content'>
                    <h2>
                        <LocalFlorist
                                    id='biotope'
                                    fontSize='inherit'
                                    className='beeseen-applications__icon'
                                />
                        Notre biotope pour communiquer en 4D
                    </h2>
                    <div className='beeseen-applications__blocks'>
                        <div className='beeseen-applications__block'>
                            <p>
                                Voici les composantes du milieu biologique de Nowlook, 
                                les briques élémentaires sur lesquelles notre agence 
                                prend appui pour faire prendre vie à vos projets
                            </p>
                            <ul>
                                <li>
                                <h3><EmojiTransportation
                                    fontSize='inherit'
                                    className='beeseen-applications__icon'
                                />
                                    <strong>Les espaces virtuels</strong>
                                    </h3>
                                </li>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-360'
                                component={Link}
                            >
                                Le 360°
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-cartoon'
                                component={Link}
                            >
                                Le mode cartoon
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-mixte'
                                component={Link}
                            >
                                Le mixte 360° - cartoon
                            </Button>
                            <br />
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-3Drealiste'
                                component={Link}
                            >
                                Le 3D réaliste
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-3Dultra'
                                component={Link}
                            >
                                Le 3D photo-réaliste
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-3Dsat'
                                component={Link}
                            >
                                Le 3D satellite
                            </Button>
                            <br /> <br /><br /> <br />
                            </ul>
                            {/* ----------------------------------------------------------- */}
                            <ul>
                                <li>
                                <h3><EmojiTransportation
                                    fontSize='inherit'
                                    className='beeseen-applications__icon'
                                />
                                    <strong>La création de valeur</strong>
                                    </h3>
                                </li>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/va-animations'
                                component={Link}
                            >
                                Les animations
                                </Button>
                                <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/va-interactivite'
                                component={Link}
                            >
                                L'interactivité
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/va-graphisme'
                                component={Link}
                            >
                                Le graphisme
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/va-physique'
                                component={Link}
                            >
                                Le monde physique
                            </Button>
                            {/* <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-3Dultra'
                                component={Link}
                            >
                                Sphères et cubes
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/applications/espace-3Dsat'
                                component={Link}
                            >
                                Les objets connectés
                            </Button> */}
                            <br /> <br /><br /> <br />
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
})
