import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace3Drealiste = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le 3D réaliste</h1>
            <p>
                Il s'agit de concevoir en 3D une scène de façon réaliste, ce qui est bien pratique quand elle 
                n'existe pas en réalité, qu'un 360° n'est pas possible.
                <br /><br />
                Le scène ci-dessous présente un stand dans un salon.
                <br />
            </p>
            <Viewer id='68e1e3052eef45bb9c1419d433e5933c' />
            <p>
                L'attention est portée sur le choix des textures et la mise en scène, afin de projeter l'internaute 
                dans cette représentation, qu'il s'y sente comme dans la réalité.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
