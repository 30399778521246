import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EntreprisesControle = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>La Salle de Contrôle</h1>
            <p>
                Voici une alternative aux espaces collaboratifs.
            </p>
            <Viewer id='1df41cd26dcb46c395a272a42e9b6045' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br />  </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
