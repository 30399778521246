import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Campingcar = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Camping-car de collection Fleurette !</h1>
            <p>
                <br />Ce modèle a été reconstitué à partir de photos de smartphone pour l'extérieur et 
                de notre GoPro Max pour l'intérieur. 
                <br />Les volumes ont été réalisés à partir de mesures au mètre à ruban, nous avons ajouté 
                une animation pour montrer comment passer en mode couchette.
                <br /><br />Vous pouvez le constater, l'expérience est très différente d'une visite 
                virtuelle standard à 360° utilisée dans l'immobilier tel que Matterport, elle offre plus 
                d'information, elle est complète et plus interactive.
                <br /><br />Profitez-en, surprenez vos clients !
                <br />
                <br />Passez en mode plein écran avec les flèches en bas à droite pour une expérience encore 
                plus immersive et n'hésitez pas à prendre la main avec le clic gauche de la souris.
                <br />
            </p>
                <Viewer id='94045cf36dee4c4a85df4c78afba963a' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br />  </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>


            
{/* --------------------------------------------------------------------- */}
            {/*
            <Viewer id='efd0af8ef3474b8d9be2165f9d45a35e'/>
            
            
            <p>
                <iframe 
                    width="100%"
                    height="600"
                    src="https://nowlook.fr/static/moto/"/>

            </p> */}
{/* --------------------------------------------------------------------- */}
        </div>
    </div>
))
