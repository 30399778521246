import LinearProgress from '@material-ui/core/LinearProgress'
import React, { memo, useEffect, useRef, useState } from 'react'
import './index.scss'

export const Viewer = memo<{ id: string; autoplay?: boolean }>(
    ({ id, autoplay = true }) => {
        const ref = useRef<HTMLIFrameElement>(null)
        const [loading, setLoading] = useState(true)
        useEffect(() => {
            const { current } = ref
            if (!current) {
                return
            }
            const client = new Sketchfab(current)
            client.init(id, {
                success: (api: any) => {
                    if (!autoplay) {
                        return
                    }
                    let loaded = false
                    let started = false
                    const observer = new IntersectionObserver(
                        ([entry]) => {
                            if (entry.intersectionRatio > 0 && !loaded) {
                                api.load()
                                loaded = true
                                setLoading(false)
                            }
                            if (entry.intersectionRatio < 0.5 && started) {
                                api.pause()
                                api.stop()
                                started = false
                            }
                            if (entry.intersectionRatio > 0.5 && !started) {
                                api.start()
                                api.play()
                                started = true
                            }
                        },
                        {
                            threshold: [0, 0.25, 0.5, 0.75, 1],
                        },
                    )
                    observer.observe(current)
                },
                camera: 0,
                preload: 1,
                ui_stop: 0,
                annotation_cycle: 5,
            })
        }, [id, autoplay])
        return (
            <div className="beeseen-viewer-container">
                <LinearProgress
                    variant={
                        loading && autoplay ? 'indeterminate' : 'determinate'
                    }
                    value={loading && autoplay ? undefined : 100}
                />
                {/*
                // @ts-ignore */}
                <iframe
                    ref={ref}
                    className='beeseen-viewer'
                    sandbox='allow-scripts allow-same-origin allow-popups allow-forms'
                    allow='autoplay; fullscreen; vr'
                    allowvr='true'
                    allowFullScreen
                />
            </div>
        )
    },
)
