import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaInteractivite = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>L'interactivité</h1>
            <p>
                Nous ne pouvons pas vous présenter actuellement nos modèles interactifs sur cette page web.
                <br /><br />
                Nous utilisons un moteur de jeu vidéo pour permettre cette interaction, et nous avons choisi 
                le moteur Unreal Engine pour cela.
                <br />Nous réalisons des applications qui sont livrées à nos clients, la confidentialité est 
                ainsi garantie, l'application est installée sur un serveur de notre client, les informations 
                contenues dans nos modèles restent dans l'application, les droits d'accès sont contrôlés.
                <br /><br />
                Dites nous en plus sur votre projet et nous pourrons vous aider, vous en dire plus, vous présenter 
                les solutions techniques qui permettent ces interactions, et les façons de les mettre en place.
                <br /><br />
                La scénarisation est très importante, nous aimons en particulier l'accroche narrative (storrytellling), 
                elle sert de guide, permet d'avancer graduellement dans l'histoire et d'impacter fortement 
                son interlocateur.
                <br />
            </p>
            
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
