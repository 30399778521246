import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Entreprises40_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            
            <h1>Industry 4.0</h1>
            <p>
                The Fourth Industrial Revolution needs 3D supports to display information in context 
                and interact with the real world. This 3D support is a Digital Twin, it's a starting point 
                of the journey.
                <br /> <br />
                <h3>Digital Twin</h3>
                We propose a unique approach, a configurator that allows you to build your own 3D model 
                based on photos taken from a simple smartphone.
                <br />You build your machine in 3D with our 
                application and drag & drop your photos.
                <br />After your machines are saved in your private library, it's available for being staged, 
                simulate a layout and your infrastructure around.
                <br /><br />Here is an example of staging:
                <br />
            </p>
            <Viewer id='adc1fe48af784ac7b1c3531556cd850c' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
