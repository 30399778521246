import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const TousArchi = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Aide à la conception architecturale</h1>
            <p>
                Voici une étude rapide réalisée pour un client qui souhaitait
                comprendre comment intégrer un escalier dans son loft.
                <br />
                <br />
                L'étude technique montrait une faiblesse de tenue du plancher,
                il fallait suspendre l'escalier.
                <br /> <br />
                Nous l'avons aidé à communiquer ses idées avec l'architecte de son syndic.
            </p>
            <Viewer id='848cd6ffafed401fb3160d2dba5aa48e' />
            <p>
                Son dossier a été accepté, nous en sommes heureux et fiers.
                <br /> <br />
                Vous avez un projet immobilier, ou le besoin de visualiser votre projet en 3 dimensions,
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
