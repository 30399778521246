import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaInteractivite_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Interactivity</h1>
            <p>
                We cannot present our interactive models with our web page because it's executable files, stored 
                in the secured servers of our customers. Doing so, confidentiality is ensured and access is controled.
                <br /><br />
                We use a game engine to provide interactions, and we have chosen Unreal Engine for this.
                <br /><br />
                Tell us more about your project and we'll help you, tell you more, present fields of possibilities and 
                ways to install interactions.
                <br /><br />
                Staging is very important, we love specificaly storrytellling, it guides navigation with high impact 
                in communication.
                <br />
            </p>
            
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
