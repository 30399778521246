import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace360 = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Le 360°</h1>
            <p>
                Utiliser le réel comme matériau de base pour communiquer, une valeur sûre. 
                <br /><br />
                Par 360°, nous entendons la téléportation dans une scène, la photo de l'endroit à visiter
                <br /><br />
                L'entrée dans la visite peut être scénarisée, telle une introduction, pour se mettre dans le contexte. 
                <br />Le modèle ci-dessous présente une introduction simple, sous la forme de logo.
                <br /><br />
                Prenez la main sur votre visite, bougez avec votre doigt ou le bouton gauche de votre souris enfoncé.
                <br />Cliquez sur les annotations pour vous téléporter dans une autre pièce.
                <br />
            </p>
            <Viewer id='193108cfa63d49b1913f1061533ee959' />
            <p>
                L'intérêt et le succès des 360° réside dans le niveau de détail qu'ils apportent, 
                l'information apportée est brute, sans filtre. Vous êtes dans le réel, par la réalité virtuelle.
                <br /><br />
                Les 360° peuvent se suivre, et vous réalisez alors une visite virtuelle, qui vous permet de vous déplacer 
                de pièce en pièce ou de suivre un chemin à la mode de google street view comme ici:
                <br />
            </p>
            <iframe 
                    width="100%"
                    height="600"
                    src="https://tour-uk.metareal.com/apps/player?asset=719271c4-f800-407a-adc1-4b6b39fdb07a"/>

            <p>
                <br /><br />
                Les photos sont prises à partir de caméra 360°, nous utilisons une GoPro Max.
                <br />A partir d'un budget de 400€, vous avez accès à ce type de matériel, il sera très vite amorti.
                <br /><br />
                Vous pouvez aussi prendre des panoramas avec votre reflex ou votre smartphone, ou de simples photos, 
                mais il faudra alors procéder à une recomposition en photogrammétrie. 
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#applications'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
