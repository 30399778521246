import Work from '@material-ui/icons/Work'
import React, { memo } from 'react'
import './index.scss'

export const About = memo(() => {
    return (
        <>
            <div className='jump' id='about-us' />
            <div className='beeseen-block beeseen-about'>
                <div className='beeseen-block__content'>
                    <h2>
                        <Work
                            fontSize='inherit'
                            className='beeseen-about__icon'
                        />
                        À propos
                    </h2>
                    <h3>Qui sommes-nous ?</h3>
                    <p>
                        Nous sommes une start-up française, localisée en
                        Bretagne, avec un bureau à Toronto.
                    </p>
                    <p>
                        Nous sommes 9 collaborateurs, incluant 4 ingénieurs (A&M
                        Paris Tech, INPG) qui assurons les fonctions principales
                        de design digital, de service web, marketing et
                        juridique.
                    </p>
                    <h3>Notre mission</h3>
                    <p>
                        Être les pionniers dans la{' '}
                        <strong>communication 4D</strong>, dans la création de
                        solutions inspirantes, dynamiques et portables
                    </p>
                    <h3>Nos Valeurs</h3>
                    <p>
                        Nos valeurs sont construites sur des principes
                        inamovibles, elles nous guident et nous motivent dans
                        notre travail quotidien : prendre soin des hommes et
                        prendre soin de la nature.
                    </p>
                    <p>
                        Ces principes sont la clé de voûte de nos décisions et
                        de nos actions. Ils sont le ciment de notre start-up et
                        le moteur de notre croissance.
                    </p>
                    <ul>
                        <li>
                            <strong>Éthique :</strong> nous sélectionnons nos
                            partenaires et nos clients en vertue de nos
                            principes et nous agissons en toute transparence.
                        </li>
                        <li>
                            <strong>Agilité :</strong> nous nous adaptons à tout
                            moment à notre environnement pour offrir la
                            meilleure qualité de prestation à nos clients, nous
                            anticipons les évolutions et nous en sommes parfois
                            à l’initiative.
                        </li>
                        <li>
                            <strong>Innovation :</strong> nous prenons soin de
                            nous améliorer continûment afin d’offrir des
                            argumentaires de ventes uniques, nous relevons des
                            challenges afin de saisir chacune des opportunités
                            qui rendra le monde meilleur pour l’humanité, la
                            planète, et notre société.
                        </li>
                    </ul>
                    <h3>Comment l'idée nous est-elle venue ?</h3>
                    <p>
                        Nous avons fait un constat simple, nous sommes des êtres
                        visuels, et nous avons imaginé que la communication
                        pouvait devenir souple et efficace grâce aux nouvelles
                        technologies.
                    </p>
                    <p>
                        Nous avons expérimenté l’effet d’une maquette digitale
                        3D devenue vivante, avec des animations qui nous
                        parlent.
                    </p>
                    <p>
                        Au creux de notre main ou avec un casque de réalité
                        virtuelle, nous naviguons dans un petit monde numérique.
                    </p>
                </div>
            </div>
        </>
    )
})
