import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const VaAnimations_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Animations</h1>
            <p>
                Animations are key in our staging, they insure realism and help a lot immersion.
                <br /><br />
                They may be simple like seagulls in sky, or elaborated like below, to explain material flows 
                in the warehouse of a manufacturing plant. Flow in with brown tote boxes and green for finish goods, 
                between warehouse and production line, totes distribution is automated.
                <br />
            </p>
            <Viewer id='639f23d72d0c45adb3520c06210cc26e' />

            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
