import React, { memo } from 'react'
import './index.scss'
import { Link } from 'react-router-dom'

export const Footer = memo(() => {
    return (
        <nav className='beeseen-block beeseen-footer'>
            <div className='beeseen-footer__content'>
                Nowlook SAS - Impasse Roz Trefeuntec 29550 Plonevez-Porzay
                <Link className='beeseen-footer__link' to='/legal'>
                    Mentions legales
                </Link>
                <Link className='beeseen-footer__link' to='/about-us'>
                    Qui sommes-nous
                </Link>
                {/* <Link className='beeseen-footer__link' to='/about-us#tools'>
                    Nos sponsors
                </Link> */}
            </div>
        </nav>
    )
})
