import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Immo1_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Walk-Through</h1>
            <p>
                Our visits are somewhat special.
                <br />We feel that the 360° visits commonly proposed miss context, moving 
                between rooms, and finally get lost.
                <br /><br />
                We propose <strong>virtual walk-throughs</strong> that include minimaps to visualize 
                spaces, with walls and windows, and locates the real estate in his area.
                <br /><br />
                Doing so, all information is available, in a single model. Animation may be added on top 
                switching automaticaly between annotations if you let it go. You can take the 
                lead at any moment of course, it's an option by default, that's convenient for those 
                who are not familiar with 3D navigation.
            </p>
                <Viewer id='de09cba78416448f81263128de0fdd5f' />
            <p>
                With us, it's simple, you share your 360° photos and we send you back a link to a 3D 
                model like the one shown here.
                <br />It's done, embed in your internet site, or sent to your potentiel buyers.
                <br /><br />
                This staging is liked as well for room rental, events, natural or industrial sites, stores, etc.
                <br />We offer this service, 360° walk-through and context in minimap and other tricks.
                <br /><br />
            </p>
                <Viewer id='8799470aa93c466199e86294cb55f12f' />
            <p>
                Contact us, this kind of walk-through is a real value add for your sales or rentals.
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
               color='secondary'
               to='/description_en'
               className='beeseen-applications__example-back-button'
               component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
