import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Museum = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>
            
            <h1>Musée virtuel</h1>
            <p>
                Bienvenue au musée de la baie !
                <br /> <br />
                Cet exemple en 3D a été conçu sur notre plateforme NLK Deck pour permettre aux administrateurs de musée de créer 
                leurs espaces et y ajouter leurs contenus, quand ils le souhaitent, en autonomie.
                <br /> <br />La navigation dans ces espaces se fait à partir de la souris, bouton gauche ou droit maintenu ou 
                double-clic gauche, c'est très simple. 
                <br />Sur smartphone, c'est le même principe, avec les doigts.
                <br />Ensuite, il s'agit de sélectionner une annotation et cliquer sur le lien.
                <br />
            </p>
            <Viewer id='ba5e79b4cea94ac0b70dc708add08206' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p>
                <br /> <br />
            </p>
            <Button
                color='secondary'
                to='/'
                className='beeseen-applications__example-back-button'
                component={Link}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
