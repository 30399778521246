import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const Espace360_en = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>360°</h1>
            <p>
                Utilizing reality as building material for your communication is clever,
                <br /><br />
                By 360°, we mean teleport into a scene, the photo of the place to be,
                <br /><br />
                The entrance to the scene must be staged, as an intro, to get the flavour, the context,
                <br />The model below present a simple intro, with a logo.
                <br /><br />
                Take the lead of this visit, use your finger or left button mouse,
                <br />Teleport to other places with the annotations.
                <br />
            </p>
            <Viewer id='193108cfa63d49b1913f1061533ee959' />
            <p>
                The interrest and success of 360° lies in the level of details they provide, 
                <br />Raw information, no filter, you are in, through the virtual reality.
                <br /><br />
                Scenes may be multiple, you create a walk through, like with google street view.
                <br />
            </p>
            <iframe 
                    width="100%"
                    height="600"
                    src="https://tour-uk.metareal.com/apps/player?asset=719271c4-f800-407a-adc1-4b6b39fdb07a"/>
            <p>
                <br />
                Photos are taken with a 360 camera, ours is a GoPro Max.
                <br />With $450, you get access to this type of material, it's amortised very quick.
                <br />
            </p>
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contact us
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/description_en'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Back
            </Button>
        </div>
    </div>
))
