import React, { memo } from 'react'
import './index.scss'

export const Legal = memo(() => (
    <div className='beeseen-block beeseen__legal'>
        <div className='beeseen-block__content'>
            <p>
                En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004
                pour la confiance dans l'économie numérique, il est précisé dans
                cet article l'identité des différents intervenants dans le cadre
                de sa réalisation et de son suivi.
                <br />
                <br />
                Le site www.nowlook.fr est édité par NOWLOOK, dont le siège
                siège social est:
                <br />
                - situé à l'adresse suivante : Impasse Roz Trefeuntec, 29550
                PLONEVEZ-PORZAY
                <br />
                - immatriculé au registre suivant : RCS : 882 655 954 Quimper
                <br />
                <br />
                SAS, société par actions simplifiée au capital de 15.000€ SIRET
                : 882 655 954 00015
                <br />
                APE : 4741Z
                <br />
                <br />
                Téléphone : +33 (0)6 26 61 36 45
                <br />
                Adresse e-mail : contact@nowlook.fr
                <br />
                <br />
                Le directeur de publication du site est : Philippe NAULLEAU
                <br />
                Le site www.nowlook.fr est hébergé par : Firebase -
                firebase.google.com
                <br />
                Emails et Noms de domaines :<br />
                VIADUC
                <br />
                5 bis bd Foch, 49100 Angers
                <br />
                https://www.viaduc.fr/
                <br />
                <br />
                Contrefaçon :<br />
                Toute reproduction ou représentation, en tout ou partie, à
                d’autres fins sur un quelconque support est interdite. Le
                non-respect de cette interdiction constitue une contrefaçon
                pouvant engager la responsabilité civile et pénale du
                contrefacteur. Les informations contenues dans ce service sont
                non contractuelles et sujettes à modification sans préavis.
            </p>
        </div>
    </div>
))
