import Button from '@material-ui/core/Button'
import ArrowBack from '@material-ui/icons/ArrowBack'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../../viewer'
import { HashLink } from 'react-router-hash-link'

export const EntreprisesCollab = memo(() => (
    <div className='beeseen-block beeseen-applications__examples'>
        <div className='beeseen-block__content'>

            <h1>Les espaces collaboratifs</h1>
            <p>
                L'idée est ici de donner un sentiment d'appartenance à l'équipe qui travaille à distance, et qui pourrait se sentir isolée. 
                <br />L'équipe choisit ses meubles et sa disposition d'affichage, et c'est bien le process de constitution 
                de son environnement qui doit être pris comme un jeu, produisant des interactions entre les membres de l'équipe, 
                les faisant décider ensemble et suscitant l'engagement.
                <br />
                <br />La deuxième clé de ce type de modèle concerne la communication. 
                <br />A la fin d'une réunion, en mode agile par exemple, 
                comme proposé sur l'un des murs des espaces, en fermant la porte, la dernière mise à jour est affichée. 
                <br />Le management a accès à l'information, ainsi que les équipes périphériques qui en ont l'autorisation.
            </p>
            <Viewer id='b90cbfc37b2147a6af1ac17dac012ed7' />
            <Button
                variant='contained'
                color='secondary'
                to='/#contact'
                component={HashLink}
            >
                Contactez-nous
            </Button>
            <p> <br /> </p>
            <Button
                color='secondary'
                to='/#exemples'
                className='beeseen-applications__example-back-button'
                component={HashLink}
            >
                <ArrowBack />
                &nbsp; Retour
            </Button>
        </div>
    </div>
))
