import Button from '@material-ui/core/Button'
import Category from '@material-ui/icons/Category'
import EmojiTransportation from '@material-ui/icons/EmojiTransportation'
import Healing from '@material-ui/icons/Healing'
import HomeWork from '@material-ui/icons/HomeWork'
import LocalFlorist from '@material-ui/icons/LocalFlorist'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Viewer } from '../viewer'
import './index.scss'

export const Exemples = memo(() => {
    return (
        <>
            <div className='jump' id='exemples' />
            <div className='beeseen-block beeseen-applications'>
                <div className='beeseen-block__content'>
                    <h2>
                        <Category
                            fontSize='inherit'
                            className='beeseen-applications__icon'
                        />
                        Des exemples inspirants<br />
                    </h2>
                    <div className='beeseen-applications__blocks'>
                        <div className='beeseen-applications__block'>
                            <p>
                                Avec nous, pas de standard, tout doit être ciblé, aussi, voici des 
                                exemples de modèles qui ne manqueront pas de vous inspirer pour les 
                                entreprises, le tourisme, l'immobilier, tous domaines professionnel, 
                                mais aussi pour tout un chacun.
                            </p>
                            <ul>
                            {/* -------------- ligne 1 ---------- */}
                            {/* <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-40'
                                component={Link}
                            >
                                Jumeaux Numériques
                            </Button> */}
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-museum'
                                component={Link}
                            >
                                Musée virtuel
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-museum2'
                                component={Link}
                            >
                                Expo temporaire
                            </Button>
                            
                            {/* -------------- ligne 2 ---------- */}
                            <br />
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-collab'
                                component={Link}
                            >
                                Les espaces collaboratifs
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-controle'
                                component={Link}
                            >
                                La Salle de Contrôle
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-salon'
                                component={Link}
                            >
                                Les salons virtuels
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-implant'
                                component={Link}
                            >
                                La simulation d'implantation
                            </Button>
                                                    
                            <br />
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/immo-1'
                                component={Link}
                            >
                                Appartement
                            </Button>
                                               
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/camping-car'
                                component={Link}
                            >
                               Camping-car
                            </Button>
{/* 
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/entreprises-40'
                                component={Link}
                            >
                               I
                            </Button> */}

                            <br />
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/tous-archi'
                                component={Link}
                            >
                                Architecture
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/tous-litho'
                                component={Link}
                            >
                                Litho 3D
                            </Button>
                            <Button
                                className='beeseen-applications__block-demo-button'
                                variant='contained'
                                color='secondary'
                                to='/exemples/tous-meditation'
                                component={Link}
                            >
                                Méditation
                            </Button>
                            <br /> <br /><br /> <br />
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})
