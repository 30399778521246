import Button from '@material-ui/core/Button'
import { HashLink } from 'react-router-hash-link'
import React, { memo } from 'react'
import logo from '../assets/logo-full-white.svg'
import logo3D from '../assets/3D.svg'
import logoAnimations from '../assets/animations.svg'
import logoLight from '../assets/light.svg'
import logoVR from '../assets/VR.svg'
import './index.scss'
import { Viewer } from '../viewer'
import Healing from '@material-ui/icons/Healing'


export const Description = memo(() => {
    return (
        <div className='beeseen-block beeseen-description'>
            <div className='beeseen-block__content'>
                <div className='beeseen-description__content'>
                    <div className='beeseen-description__text'>

                    <Button
                        variant='contained'
                        color='secondary'
                        to='/description_en'
                        component={HashLink}
                        className='beeseen-header__link'
                    >
                        English
                    </Button>
                        
                        <h2 className='beeseen-description__text-title'>
                            <br />Experts en solutions de réalité virtuelle innovantes
                        </h2>
                        <div className='beeseen-description__text-general'>
                            Utilisez la <strong>4D</strong> pour communiquer efficacement,
                            <br />
                            l’immersion dans des espaces virtuels en 3D, 
                            avec la vie en plus, la 4ème Dimension,
                            <br />pour une expérience numérique unique.
                        </div>
                    </div>
                    <div className='beeseen-description__logo'>
                        <div dangerouslySetInnerHTML={{ __html: logo }} />
                        <h3>4D Communication</h3>
                    </div>
                </div>
                <div className='beeseen-description__product-icons'>
                    <div className='beeseen-description__product-icon'>
                        <div dangerouslySetInnerHTML={{ __html: logo3D }}></div>
                        <p>Maquettes 3D</p>
                    </div>
                    <div className='beeseen-description__product-icon'>
                        <div
                            dangerouslySetInnerHTML={{ __html: logoAnimations }}
                        ></div>
                        <p>Animées</p>
                    </div>
                    <div className='beeseen-description__product-icon'>
                        <div
                            dangerouslySetInnerHTML={{ __html: logoLight }}
                        ></div>
                        <p>Légères & portables</p>
                    </div>
                    <div className='beeseen-description__product-icon'>
                        <div dangerouslySetInnerHTML={{ __html: logoVR }}></div>
                        <p>Equipement spécial, ... ou pas</p>
                    </div>                    
                </div>
                {/* -------------- transféré du bloc applications vers description 
                pour que le retour se fasse directement sur applications et pas en haute de page -------------------- */}
                <div className='beeseen-block beeseen-applications'>
                    <div className='beeseen-block__content'>
                        <div className='beeseen-applications__block'>
                            <Viewer id='ba5e79b4cea94ac0b70dc708add08206' />
                            {/* <Viewer id='d9f747bf15d744aabdc56eae45ddb9ef' /> */}
                            {/* <Viewer id='146d356f26ee4761b59ed83272eb26fe' /> */}
                        </div>
                    </div>
                </div>
                <div>
                    <br /><br />
                </div>
                
                
            </div>
        </div>
    )
})
